import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`👋, I'm Oriol`}</h1>
    <p>{`I am a Software Engineer based in Barcelona ❤️. My passion is to transform ideas into products for the market. I love to find creative ways to solve `}<strong parentName="p">{`problems`}</strong>{` to exponentially improve our lives.`}</p>
    <p><strong parentName="p">{`C-level`}</strong>{` since 2012 (excluding a couple of occasions), being `}<strong parentName="p">{`CTO`}</strong>{` and `}<strong parentName="p">{`CPO`}</strong>{`, and sometimes starting companies as a `}<strong parentName="p">{`Founder`}</strong>{` 💪. Now I'm doing my best as `}<strong parentName="p">{`Director of Engineering`}</strong>{` at `}<a parentName="p" {...{
        "href": "https://factorialhr.com/"
      }}>{`Factorial`}</a>{` 💜.`}</p>
    <p>{`I'm a strong advocate of having `}<strong parentName="p">{`Side Projects`}</strong>{`. I always try to have at least one to feed my appetite for playing with some new tech and learning new stuff. I try to make them `}<strong parentName="p">{`profitable`}</strong>{` but so far I haven't been very lucky 🌝💰.`}</p>
    <p>{`But above all, I'm a family man: Happily married and blessed father of 2 `}<em parentName="p">{`little lions`}</em>{` 🦁🦁.`}</p>
    <p>{`Now I build stuff @ `}<a parentName="p" {...{
        "href": "https://factorialhr.com/"
      }}>{`Factorial`}</a>{`,  previously at `}<a parentName="p" {...{
        "href": "https://getquipu.com/"
      }}>{`Quipu`}</a>{`,  `}<a parentName="p" {...{
        "href": "https://billymob.com/en/"
      }}>{`Billy Mobile`}</a>{`,  Kerad Games,  `}<strong parentName="p">{`Crows & Dogs`}</strong>{`,  `}<a parentName="p" {...{
        "href": "https://feverup.com/"
      }}>{`Fever`}</a>{`,  `}<a parentName="p" {...{
        "href": "http://ideaknow.com/"
      }}>{`Ideaknow`}</a>{`,  Tupply,  Soma Barcelona and `}<a parentName="p" {...{
        "href": "https://www.tmb.cat/es/home"
      }}>{`TMB`}</a>{` (`}<em parentName="p">{`Lol I'm too old`}</em>{` 👴🏻).`}</p>
    <p>{`I always enjoy having a chat,  so don't hesitate to send me an `}<a parentName="p" {...{
        "href": "mailto:hi@oriol.dev"
      }}>{`email`}</a>{` or drop me a message on `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/oriolblanc/"
      }}>{`Linkedin`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      